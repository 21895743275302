<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.mitarbeiter.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div
      class="app-content-page"
      v-loading="exportLoading"
      element-loading-text="Exportieren..."
    >
      <h1 class="app-content-title">
        <app-i18n code="entities.mitarbeiter.list.title"></app-i18n>
      </h1>

      <app-mitarbeiter-list-toolbar></app-mitarbeiter-list-toolbar>
      <app-mitarbeiter-list-filter></app-mitarbeiter-list-filter>
      <app-mitarbeiter-list-table></app-mitarbeiter-list-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MitarbeiterListFilter from '@/modules/mitarbeiter/components/mitarbeiter-list-filter.vue';
import MitarbeiterListTable from '@/modules/mitarbeiter/components/mitarbeiter-list-table.vue';
import MitarbeiterListToolbar from '@/modules/mitarbeiter/components/mitarbeiter-list-toolbar.vue';

export default {
  name: 'app-mitarbeiter-list-page',
  components: {
    [MitarbeiterListFilter.name]: MitarbeiterListFilter,
    [MitarbeiterListTable.name]: MitarbeiterListTable,
    [MitarbeiterListToolbar.name]: MitarbeiterListToolbar,
  },
  computed: {
    ...mapGetters({
      exportLoading: 'mitarbeiter/list/exportLoading',
    }),
  },
};
</script>

<style></style>
